<template>
  <div>
    <transition
      appear
      name="fade-slide"
    >
      <headerBlockVip />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <next-generation-vip />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <ControlMethod :settings="settings" />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <DesignSiteVip />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <powering-ministries-vip />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <hubspot-form-nine />
    </transition>
    <transition
      appear
      name="fade-slide"
    >
      <footerBlockVip />
    </transition>
  </div>
</template>

<script>
import nextGenerationVip from '@/components/nextGenerationVip';
import ControlMethod from '@/components/controlMethod';
import DesignSiteVip from '@/components/designSiteVip';
import poweringMinistriesVip from '@/components/poweringMinistriesVip';
import hubspotFormNine from '@/components/hubspotFormNine'; // new form
// import hubspotForm from '@/components/hubspotForm';
import footerBlockVip from '@/components/footerBlockVip';
import headerBlockVip from '@/components/headerBlockVip';

export default {
  name: 'VipPage',
  components: {
    ControlMethod,
    nextGenerationVip,
    DesignSiteVip,
    poweringMinistriesVip,
    hubspotFormNine,
    // hubspotForm,
    footerBlockVip,
    headerBlockVip,
  },
  props: ['showModalVideo'],
  data() {
    return {
      windowEl: window,
      windowOldScrollPosition: 0,
      setTimeoutId: 0,
      showContentImgScrollDown: true,
      showContentImgScrollUp: true,
      settings: {
        title: 'Cut your costs. Stay lean & efficient',
        showSubtitle: false,
      },
    };
  },
  computed: {
    pricingObj: {
      get() {
        return this.$store.getters.computedPricingObj;
      },
      set(data) {
        this.$store.commit('setPricingObj', data);
      },
    },
    showModalVideoIndexPage: {
      get() {
        return this.showModalVideo;
      },
      set(data) {
        this.$emit('update:showModalVideo', data);
      },
    },
  },
  beforeMount() {
    const to = this.$route;
    const nearestWithMeta = to.meta.metaTags;
    nearestWithMeta.map((tagDef) => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
      .forEach((tag) => document.head.appendChild(tag));
  },
  beforeDestroy() {
    Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) => el.parentNode.removeChild(el));
  },
};
</script>

<style lang="scss">
</style>
